import { PaginationEntity } from '@core/pagination/entity';
import httpRepository from '@core/repository/http';
import { OptionEntity, TableEntity } from '@core/table';

import PaymentHistoriesEntity from './paymentHistoriesEntity';
import TariffPackagesEntity from './tariffPackagesEntity';

// API GET
export const getListTariffPackages = (pagination: PaginationEntity, options: OptionEntity) => {
  const params = new TableEntity(pagination, options);
  return httpRepository.execute({
    path: '/api/TariffPackages',
    showSuccess: false,
    showError: false,
    params,
    convert: res => {
      return {
        data: TariffPackagesEntity.createListTariffPackages(res?.pagedData),
        info: new PaginationEntity(res?.pageInfo),
      };
    },
  });
};
//and get detail
export const getDetailTariffPackages = (id: string) => {
  return httpRepository.execute({
    path: '/api/TariffPackages/' + id,
    showSuccess: false,
    showError: false,
    convert: res => {
      return new TariffPackagesEntity(res);
    },
  });
};

//API ADD
export const createTariffPackages = (payload: any) => {
  return httpRepository.execute({
    path: '/api/TariffPackages',
    method: 'post',
    payload,
  });
};

//API EDIT/UPDATE
export const updateTariffPackages = (id: string, payload: any) => {
  return httpRepository.execute({
    path: '/api/TariffPackages/' + id,
    method: 'put',
    payload,
  });
};

//API DELETE
export const deleteTariffPackages = (id: string) => {
  return httpRepository.execute({
    path: '/api/TariffPackages/' + id,
    method: 'delete',
  });
};

export const deleteManyTariffPackages = (payload: any) => {
  return httpRepository.execute({
    path: '/api/TariffPackages/DeleteMany',
    method: 'post',
    payload,
  });
};
// API GET
export const getPaymentHistories = (pagination: PaginationEntity, options: OptionEntity) => {
  const params = new TableEntity(pagination, options);
  return httpRepository.execute({
    path: '/api/PaymentHistories/PaymentHistories',
    showSuccess: false,
    showError: false,
    params,
    convert: res => {
      return {
        data: PaymentHistoriesEntity.createListPaymentHistories(res?.pagedData),
        moreInfo: res?.moreInfo,
        info: new PaginationEntity(res?.pageInfo),
      };
    },
  });
};
