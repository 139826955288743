export default {
  'dashboard.router.manager': 'Dashboard',
  'dashboard.summary.organizations': 'Accounts',
  'dashboard.summary.organizations.expired': 'Expired Accounts',
  'dashboard.summary.organizations.due': 'Active Accounts',
  'dashboard.summary.capacityUsed.text': 'In Used Storage',
  'dashboard.summary.capacity.text': 'Total Registered',
  'dashboard.filter.organizations': 'Accounts',
  'dashboard.filter.status': 'Status',
  'dashboard.filter.status.active': 'Active',
  'dashboard.filter.status.notActive': 'Inactive',
  'dashboard.marker.device.name': 'Device Name',
  'dashboard.marker.organizations': 'Account',
  'dashboard.marker.specificAddress': 'Installation Address',
  'dashboard.marker.latitude': 'Latitude',
  'dashboard.marker.longitude': 'Longitude',
  'dashboard.marker.status': 'Status',
  'dashboard.marker.device.info': 'Device Information',
  'dashboard.marker.devices.operationStatus.id':
    '{status,select, 1 {Active} 2 {Inactive} other {Undefined}}',
  'dashboard.summary.facilities': 'Total Locations',
  'dashboard.summary.totalDevice': 'Total Devices',
  'dashboard.summary.totalNewsletter': 'Total Broadcast',
  'dashboard.summary.capacity': 'Total Capacity',
  'dashboard.summary.totalDevice.label': 'Maximum',
  'dashboard.summary.totalDevice.valueDesc': 'device(s)',
};
