/* eslint-disable no-unused-vars */
import RoleEntity from '@modules/roles/RoleEntity';

class PermissionsEntity {
  resource: string = '';

  code: string = '';

  name: string = '';

  permissions: RoleEntity[] = [];

  constructor(permissions: Partial<PermissionsEntity>) {
    if (!permissions) {
      return;
    }
    Object.assign(this, permissions);
  }

  static createListPermissions(listPermissions: Array<Partial<PermissionsEntity>>) {
    if (!Array.isArray(listPermissions)) {
      return [];
    }
    return listPermissions.map((permissions: Partial<PermissionsEntity>) => {
      return new PermissionsEntity(permissions);
    });
  }
}

export enum PermissionEnum {
  DevicesAdjustVolume = 'DevicesAdjustVolume',
  DevicesCreate = 'DevicesCreate',
  DevicesDelete = 'DevicesDelete',
  DevicesPlaylogsView = 'DevicesPlaylogsView',
  DevicesReboot = 'DevicesReboot',
  DevicesUpdate = 'DevicesUpdate',
  DevicesView = 'DevicesView',
  DevicesViewDetail = 'DevicesViewDetail',
  FacilitiesCreate = 'FacilitiesCreate',
  FacilitiesDelete = 'FacilitiesDelete',
  FacilitiesDevicesAdjustVolume = 'FacilitiesDevicesAdjustVolume',
  FacilitiesDevicesCreate = 'FacilitiesDevicesCreate',
  FacilitiesDevicesDelete = 'FacilitiesDevicesDelete',
  FacilitiesDevicesPlaylogsView = 'FacilitiesDevicesPlaylogsView',
  FacilitiesDevicesReboot = 'FacilitiesDevicesReboot',
  FacilitiesDevicesUpdate = 'FacilitiesDevicesUpdate',
  FacilitiesDevicesView = 'FacilitiesDevicesView',
  FacilitiesDevicesViewDetail = 'FacilitiesDevicesViewDetail',
  FacilitiesMediasApprove = 'FacilitiesMediasApprove',
  FacilitiesMediasCreate = 'FacilitiesMediasCreate',
  FacilitiesMediasDelete = 'FacilitiesMediasDelete',
  FacilitiesMediaSharesView = 'FacilitiesMediaSharesView',
  FacilitiesMediasShare = 'FacilitiesMediasShare',
  FacilitiesMediasUpdate = 'FacilitiesMediasUpdate',
  FacilitiesMediasView = 'FacilitiesMediasView',
  FacilitiesMediasViewDetail = 'FacilitiesMediasViewDetail',
  FacilitiesPlaylistsCreate = 'FacilitiesPlaylistsCreate',
  FacilitiesPlaylistsDelete = 'FacilitiesPlaylistsDelete',
  FacilitiesPlaylistsUpdate = 'FacilitiesPlaylistsUpdate',
  FacilitiesPlaylistsView = 'FacilitiesPlaylistsView',
  FacilitiesPlaylistsViewDetail = 'FacilitiesPlaylistsViewDetail',
  FacilitiesTemplatesCreate = 'FacilitiesTemplatesCreate',
  FacilitiesTemplatesDelete = 'FacilitiesTemplatesDelete',
  FacilitiesTemplatesUpdate = 'FacilitiesTemplatesUpdate',
  FacilitiesTemplatesView = 'FacilitiesTemplatesView',
  FacilitiesTemplatesViewDetail = 'FacilitiesTemplatesViewDetail',
  FacilitiesUpdate = 'FacilitiesUpdate',
  FacilitiesUsersCreate = 'FacilitiesUsersCreate',
  FacilitiesUsersDelete = 'FacilitiesUsersDelete',
  FacilitiesUsersUpdate = 'FacilitiesUsersUpdate',
  FacilitiesUsersView = 'FacilitiesUsersView',
  FacilitiesUsersViewDetail = 'FacilitiesUsersViewDetail',
  FacilitiesView = 'FacilitiesView',
  FacilitiesViewDetail = 'FacilitiesViewDetail',
  FieldsCreate = 'FieldsCreate',
  FieldsDelete = 'FieldsDelete',
  FieldsUpdate = 'FieldsUpdate',
  FieldsView = 'FieldsView',
  FieldsViewDetail = 'FieldsViewDetail',
  MediasApprove = 'MediasApprove',
  MediasCreate = 'MediasCreate',
  MediasDelete = 'MediasDelete',
  MediaSharesDelete = 'MediaSharesDelete',
  MediaSharesReception = 'MediaSharesReception',
  MediaSharesView = 'MediaSharesView',
  MediasShare = 'MediasShare',
  MediasUpdate = 'MediasUpdate',
  MediasView = 'MediasView',
  MediasViewDetail = 'MediasViewDetail',
  NewslettersApprove = 'NewslettersApprove',
  NewslettersCancel = 'NewslettersCancel',
  NewslettersCreate = 'NewslettersCreate',
  NewslettersDelete = 'NewslettersDelete',
  NewslettersPlayback = 'NewslettersPlayback',
  NewslettersPlaylogsView = 'NewslettersPlaylogsView',
  NewslettersSubUnitPlaylogsView = 'NewslettersSubUnitPlaylogsView',
  NewslettersSubUnitView = 'NewslettersSubUnitView',
  NewslettersSubUnitViewDetail = 'NewslettersSubUnitViewDetail',
  NewslettersUpdate = 'NewslettersUpdate',
  NewslettersView = 'NewslettersView',
  NewslettersViewDetail = 'NewslettersViewDetail',
  OrganizationsPaymentHistoriesView = 'OrganizationsPaymentHistoriesView',
  OrganizationsSettingsUpdate = 'OrganizationsSettingsUpdate',
  OrganizationsSettingsView = 'OrganizationsSettingsView',
  OverviewsEndUserView = 'OverviewsEndUserView',
  PlaylistsCreate = 'PlaylistsCreate',
  PlaylistsDelete = 'PlaylistsDelete',
  PlaylistsUpdate = 'PlaylistsUpdate',
  PlaylistsView = 'PlaylistsView',
  PlaylistsViewDetail = 'PlaylistsViewDetail',
  RolesCreate = 'RolesCreate',
  RolesDelete = 'RolesDelete',
  RolesUpdate = 'RolesUpdate',
  RolesView = 'RolesView',
  RolesViewDetail = 'RolesViewDetail',
  SuppliersCreate = 'SuppliersCreate',
  SuppliersDelete = 'SuppliersDelete',
  SuppliersUpdate = 'SuppliersUpdate',
  SuppliersView = 'SuppliersView',
  SuppliersViewDetail = 'SuppliersViewDetail',
  TemplatesCreate = 'TemplatesCreate',
  TemplatesDelete = 'TemplatesDelete',
  TemplatesUpdate = 'TemplatesUpdate',
  TemplatesView = 'TemplatesView',
  TemplatesViewDetail = 'TemplatesViewDetail',
  UsersCreate = 'UsersCreate',
  UsersDelete = 'UsersDelete',
  UsersUpdate = 'UsersUpdate',
  UsersView = 'UsersView',
  UsersViewDetail = 'UsersViewDetail',
  MediasMediaSharesView = 'MediasMediaSharesView',
  MediasRelatedNewslettersView = 'MediasRelatedNewslettersView',
  TemplatesRelatedNewsletterssView = 'TemplatesRelatedNewsletterssView',
  PlaylistsRelatedNewslettersView = 'PlaylistsRelatedNewslettersView',
  DevicesActivitieslogsView = 'DevicesActivitieslogsView',
  DevicesSchedulesView = 'DevicesSchedulesView',
  FacilitiesMediasMediaSharesView = 'FacilitiesMediasMediaSharesView',
  FacilitiesMediasRelatedNewslettersView = 'FacilitiesMediasRelatedNewslettersView',
  FacilitiesPlaylistsRelatedNewslettersView = 'FacilitiesPlaylistsRelatedNewslettersView',
  FacilitiesTemplatesRelatedNewslettersView = 'FacilitiesTemplatesRelatedNewslettersView',
  FacilitiesDevicesActivitieslogsView = 'FacilitiesDevicesActivitieslogsView',
  FacilitiesDevicesSchedulesView = 'FacilitiesDevicesSchedulesView',

  // Permissions of System user
  AnnouncementsCreate = 'AnnouncementsCreate',
  AnnouncementsDelete = 'AnnouncementsDelete',
  AnnouncementsUpdate = 'AnnouncementsUpdate',
  AnnouncementsView = 'AnnouncementsView',
  AnnouncementsViewDetail = 'AnnouncementsViewDetail',
  OrganizationsCreate = 'OrganizationsCreate',
  OrganizationsDelete = 'OrganizationsDelete',
  OrganizationsManage = 'OrganizationsManage',
  OrganizationsRenewal = 'OrganizationsRenewal',
  OrganizationsTruncate = 'OrganizationsTruncate',
  OrganizationsUnUsed = 'OrganizationsUnUsed',
  OrganizationsUpdate = 'OrganizationsUpdate',
  OrganizationsView = 'OrganizationsView',
  OrganizationsViewDetail = 'OrganizationsViewDetail',
  OverviewsSystemUserView = 'OverviewsSystemUserView',
  PackagesCreate = 'PackagesCreate',
  PackagesDelete = 'PackagesDelete',
  PackagesPaymentHistoriesView = 'PackagesPaymentHistoriesView',
  PackagesUpdate = 'PackagesUpdate',
  PackagesView = 'PackagesView',
  PackagesViewDetail = 'PackagesViewDetail',
  UsersViewUnAuthorized = 'UsersViewUnAuthorized',
}

export default PermissionsEntity;
