import dayjs from 'dayjs';

import { BusinessStatusExtendEnum, Packages } from './interface';

class TariffPackagesEntity {
  //copy props from backend:
  createdAt = '';

  id = '';

  capacity: number = 0;

  name: string = '';

  packages?: Packages[];

  numberOfPurchases?: number;

  periodByMonth?: any;

  expiredTime?: any;

  price?: number;

  key?: string;

  effectiveTime?: string;

  businessStatusExtend?: BusinessStatusExtendEnum;

  constructor(tariffPackages: Partial<TariffPackagesEntity>) {
    if (!tariffPackages) {
      return;
    }
    Object.assign(this, tariffPackages);
    // convert entity type here
    this.createdAt = tariffPackages.createdAt
      ? dayjs(tariffPackages.createdAt).format('DD/MM/YYYY')
      : '';
    this.key = tariffPackages.id;
  }

  static createListTariffPackages(listTariffPackages: Array<Partial<TariffPackagesEntity>>) {
    if (!Array.isArray(listTariffPackages)) {
      return [];
    }
    return listTariffPackages.map((tariffPackages: Partial<TariffPackagesEntity>) => {
      return new TariffPackagesEntity(tariffPackages);
    });
  }
}
export default TariffPackagesEntity;
