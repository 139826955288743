import { Popover } from 'antd';
import React from 'react';

import { iconDot } from '@assets/images';
import CONFIG from '@config';

const IFrame = () => {
  const content = (
    <iframe src={`${CONFIG.LOGIN_PAGE}application-iframe`} width="100%" height="100%" />
  );

  return (
    <Popover
      content={content}
      trigger="click"
      overlayClassName="ifame-popover"
      getPopupContainer={() => document.getElementById('header') as any}
      placement="rightBottom"
      arrow={false}
    >
      <img src={iconDot} alt="" className="icon-dot" />
    </Popover>
  );
};

export default React.memo(IFrame);
