import React from 'react';

import { UilSetting } from '@iconscout/react-unicons';
import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';
import { routerOrganizationFields } from '@view/SettingEndUser/Fields/router';
import { routerOrganizationOrganizationSetting } from '@view/SettingEndUser/OrganizationSetting/router';
import { routerOrganizationSuppliers } from '@view/SettingEndUser/Suppliers/router';
import { routerOrganizationUserLogs } from '@view/SettingEndUser/UserLogs/router';

import { routerOrganizationRole, routerRole } from './Users/router';

export const routerSettingSystem: IRouter = {
  path: null,
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'settings.systemUser.router.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <UilSetting />,
    activePath: '/settings',
  },

  routes: [routerRole],
  permissionCode: [
    PermissionEnum.RolesView,
    PermissionEnum.UsersView,
    PermissionEnum.UsersViewUnAuthorized,
  ],
};

export const routerOrganizationSettingSystem: IRouter = {
  path: null,
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'settings.systemUser.router.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <UilSetting />,
    activePath: '/settings/organization',
  },
  routes: [
    routerOrganizationSuppliers,
    routerOrganizationFields,
    routerOrganizationRole,
    routerOrganizationUserLogs,
    routerOrganizationOrganizationSetting,
  ],
};
