import { useLocation, useNavigate } from 'react-router-dom';

import CONFIG from '@config';

export const loadLocalHistory = (): any[] => {
  const storedHistory = localStorage.getItem(CONFIG.ROUTER_HISTORY);
  return storedHistory ? JSON.parse(storedHistory) : [];
};

export const saveLocalHistory = (history: any[]) => {
  localStorage.setItem(CONFIG.ROUTER_HISTORY, JSON.stringify(history));
};

export const useBackRouter = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const goBack = () => {
    const localHistory = loadLocalHistory();
    if (localHistory.length > 1) {
      const previousRoute = localHistory[0]?.fullPath;
      if (previousRoute) {
        if (location.state) {
          navigate(previousRoute, { state: JSON.parse(location.state) });
        } else {
          navigate(previousRoute);
        }
        const newHistory = [localHistory[1]];
        saveLocalHistory(newHistory);
      }
    }
  };

  return goBack;
};
