import { SorterResult } from 'antd/lib/table/interface';
import dayjs, { Dayjs } from 'dayjs';
import lodash from 'lodash';

import { PaginationEntity } from '@core/pagination/entity';

type OP = '$eq' | '$gt' | '$gte' | '$lt' | '$lte' | '$ilike';
type OP2 = '$btw' | '$in';

interface IFilterNor {
  value: string | number;
  op?: OP | `$not:${OP}`;
}

interface IFilterIn {
  value: Array<string | number | Dayjs>;
  op: '$in' | '$not:$in';
}

interface IFilterBtw {
  value: [string, string] | [number, number] | [Dayjs, Dayjs];
  op: '$btw' | '$not:$btw';
}

interface IFilterNull {
  value: undefined;
  op: '$not:$null' | '$null';
}

export interface IFilter {
  [propName: string]: IFilterNor | IFilterBtw | IFilterIn | IFilterNull | undefined;
}

export class OptionEntity {
  search?: string;

  filterDate?: Date;

  filter?: IFilter;

  sorter?: SorterResult<any>;

  filterOld?: { [propName: string]: string | number };

  constructor(option) {
    if (option == null) {
      return;
    }
    Object.assign(this, option);
  }
}

export class TableEntity extends PaginationEntity {
  sortQuery?: Array<string>;

  searchKeyword?: string;

  constructor(pagination: any, option?: OptionEntity) {
    super(pagination);
    this.searchKeyword = option?.search;
    this.sortQuery = this.buildSortQuery(option?.sorter as any);
    this.applyFilters(option?.filter);
    Object.assign(this, option?.filterOld);
  }

  private buildSortQuery(sorter?: {
    field: string | string[];
    order: string;
  }): string[] | undefined {
    if (!sorter || sorter.order == null) return undefined;

    const order = sorter.order === 'ascend' ? 'asc' : 'desc';
    const field = Array.isArray(sorter.field) ? sorter.field.join('.') : sorter.field;
    return sorter.field ? [`${field} ${order}`] : undefined;
  }

  private applyFilters(filters?: Record<string, any>): void {
    if (!filters) return;

    for (const key in filters) {
      if (!filters.hasOwnProperty(key)) continue;

      const filterValue = lodash.get(filters, key);

      if (filterValue == null) continue;

      if (typeof filterValue === 'string' && filterValue.startsWith('$')) {
        this[`filter.${key}`] = filterValue;
      } else if (typeof filterValue === 'string' || typeof filterValue === 'number') {
        this[key] = filterValue === '0' ? undefined : filterValue;
      } else {
        this[`filter.${key}`] = this.buildComplexFilter(key, filterValue);
      }
    }
  }

  private buildComplexFilter(key: string, filter: any): string | undefined {
    if (!filter.op) {
      filter.op = '$eq';
    }

    if (['$btw', '$in', '$not:$btw', '$not:$in'].includes(filter.op)) {
      return this.formatArrayFilter(filter.op, filter.value);
    }

    return filter.value ? `${filter.op}:${filter.value}` : undefined;
  }

  private formatArrayFilter(op: string, values: any[]): string {
    return values?.map(value => (dayjs.isDayjs(value) ? value.toISOString() : value))?.join(',') ||
      ''
      ? `${op}:${values
          .map(value => (dayjs.isDayjs(value) ? value.toISOString() : value))
          .join(',')}`
      : `${op}:`;
  }
}
