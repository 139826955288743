import React from 'react';

import { UilSetting } from '@iconscout/react-unicons';
import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';
import { routerRole } from '@view/SettingSystem/Users/router';

import { routerFields } from './Fields/router';
import { routerOrganizationSetting } from './OrganizationSetting/router';
import { routerSuppliers } from './Suppliers/router';
import { routerUserLogs } from './UserLogs/router';

export const routerSettingEndUser: IRouter = {
  path: null,
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'settings.endUser.router.user.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <UilSetting />,
    activePath: '/settings',
  },
  permissionCode: [
    PermissionEnum.SuppliersView,
    PermissionEnum.FieldsView,
    PermissionEnum.RolesView,
    PermissionEnum.OrganizationsSettingsView,
    PermissionEnum.UsersView,
  ],
  routes: [routerSuppliers, routerFields, routerRole, routerUserLogs, routerOrganizationSetting],
};
